import heads from '../designs/dist/img/heads.png'
import React from 'react'
import Layout from '../components/Layout'
import '../designs/dist/css/main.min.css'
import '../designs/dist/css/bootstrap.min.css'
import '../designs/dist/js/slick/slick.css'
import gozda from '../img/team/gozda.jpg'
import lolezy from '../img/team/lolezy.jpg'
import daniel from '../img/team/daniel.jpg'
import twarzer from '../img/team/twarzer.jpg'
import andre from '../img/team/and_re.jpg'
import slawomir from '../img/team/sławomir.jpg'
import pingwin from '../img/team/pingwin.jpg'
import dawid from '../img/team/dawid.png'
import psiemieniuk from '../img/team/psiemieniuk.png'
import cywil from '../img/team/cywil.jpg'
import igor from '../img/team/igor.jpg'
import wilk from '../img/team/wilk.jpg'
import ponton from '../img/team/ponton.jpg'
import sawa from '../img/team/sawa.jpg'
import sowa from '../img/team/sowa.jpg'
import karo from '../img/team/karo.jpg'
import jagoda from '../img/team/jagoda.jpg'
import ania from '../img/team/ania.jpg'
import marek from '../img/team/marek.png'

class team extends React.Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    return (
      <>
        <Layout
          menuVisible={this.state.menuVisible}
          toggleMenu={this.toggleMenu}
        >
          <section className="hero special">
            <div className="plus big plus-2 animated slow glitter delay1" />
            <div className="plus small plus-1 animated slow glitter delay2" />
            <div className="plus big plus-3 animated slow glitter" />
            <div className="plus small plus-4 animated slow glitter delay3" />
            <div className="plus small plus-5" />
            <div className="container">
              <div className="row team">
                <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-center align-items-start flex-column text-white">
                  <h1>
                    <span className="white-effect">That's what</span>
                    <br />
                    <span className="blue-effect">makes us</span>
                    <br />
                    <span className="white-effect">special</span>
                  </h1>
                  <label>
                    First we were friends then became programmers.
                    <br />
                    Most of us know the rest of the team for over 10 years
                    <br />
                    And we just love working together
                  </label>
                  {/*<a className="btn btn-hero animated tada" href="#">*/}
                  {/*get to know us*/}
                  {/*</a>*/}
                </div>
                <div className="col-12 col-lg-6 head-holder">
                  <img src={heads} alt="heads" />
                </div>
              </div>
            </div>
          </section>
          <section className="section-testimonials pb-0">
            <div className="container">
              <div className="row justify-content-center align-items-start">
                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={gozda} alt="Łukasz Gandecki" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Łukasz Gandecki
                      </label>
                    </div>
                  </div>
                  <p>Founder / Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={lolezy} alt="Michał Opidowicz" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Michał Opidowicz
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={daniel} alt="Daniel Strąk" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Daniel Strąk
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={twarzer} alt="Piotr Wójcik" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Piotr Wójcik
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={pingwin} alt="Przemysław Gierski" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Przemysław Gierski
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={andre} alt="Andżelo Mazurek" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Andżelo Mazurek
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={slawomir} alt="Sławomir Borzdyński" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Sławomir Borzdyński
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={wilk} alt="Tomasz Wilczek" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Tomasz Wilczek
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={igor} alt="Igor Pasieczny" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Igor Pasieczny
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={ponton} alt="Tomasz Gierczyński" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Tomasz Gierczyński
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={cywil} alt="Rafał Cywiński" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Rafał Cywiński
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={sawa} alt="Mateusz Sawka" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Mateusz Sawka
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={dawid} alt="Dawid Kisała" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Dawid Kisała
                      </label>
                    </div>
                  </div>
                  <p>Senior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={psiemieniuk} alt="Piotr Siemieniuk" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Piotr Siemieniuk
                      </label>
                    </div>
                  </div>
                  <p>Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={marek} alt="Marek Danelczyk" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Marek Danelczyk
                      </label>
                    </div>
                  </div>
                  <p>Junior Software Developer</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={sowa} alt="Karol Sowiński" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Karol Sowiński
                      </label>
                    </div>
                  </div>
                  <p>Content Creator</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={ania} alt="Anna Pawliczuk" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Anna Pawliczuk
                      </label>
                    </div>
                  </div>
                  <p>QA/Content Creator</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={karo} alt="Karolina Klępka" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Karolina Klępka
                      </label>
                    </div>
                  </div>
                  <p>Office Manager</p>
                </div>

                <div className="col-12 col-md-6 col-lg4 col-xl-3 team-holder">
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="member-image">
                      <img src={jagoda} alt="Jagoda Machajek" />
                    </div>
                    <div className="dark-holder">
                      <label className="section-title white">
                        Jagoda Machajek
                      </label>
                    </div>
                  </div>
                  <p>Assistant</p>
                </div>
              </div>
            </div>
          </section>
          <section className="section-works-blog pt-3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="dark-holder">
                    <h2 className="h1 section-title white">
                      <span className="white-effect start">How we</span>
                      <br />
                      <span className="start">work</span>
                    </h2>
                  </div>
                </div>
                <div className="col-12 col-md-8 mt-5 mt-md-0 pl-3 pl-md-5">
                  <label>
                    <p>
                      <strong>
                        Applying many of the extreme programming principles, we
                        make the development process fast and effective.
                      </strong>
                    </p>
                  </label>
                  <p>
                    <ul>
                      <li>
                        We do pair programming most of the time. In other words
                        –
                        <strong>
                          {' '}
                          you get two coders for the price of one.
                        </strong>
                      </li>
                      <li>
                        We{' '}
                        <strong>
                          love programming together, sharing the knowledge and
                          helping each other
                        </strong>
                      </li>
                      <li>
                        We doublecheck our work to produce better
                        <strong> quality code</strong>
                      </li>
                      <li>
                        We are
                        <strong> laser focused </strong>
                        (facebook is less tempting when someone is watching your
                        screen)
                      </li>
                      <li>
                        We are
                        <strong> very responsive </strong>– expect to get
                        instant replies from one of us 16-18 hours a day
                      </li>
                    </ul>
                  </p>
                  <label>
                    <p>
                      Those are a few things that make the experience of working
                      with us
                      <strong>
                        {' '}
                        so different from working with individuals.
                      </strong>
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

export default team
